
import { Component, Vue, Prop } from 'vue-property-decorator';
import MessageModal from '@/components/app/MessageModal.vue';
import Modal from '@/components/_common/Modal.vue';
import AssetDetails from './AssetDetails.vue';
import { SellOffer, CreateSellOffer } from '@/store/trading/types';
import { Getter, Action } from 'vuex-class';
import { Asset } from '@/store/trading/types';
import { centsToEuro, currency } from '@/filters/currency';
import Fieldset from '@/components/_common/Fieldset.vue';
import Stepper from '@/components/trading/Stepper.vue';
import _ from 'lodash';
import { Steps } from '@/store/stepper/types';

@Component({
  components: {
    Modal,
    AssetDetails,
    Fieldset,
    Stepper,
    MessageModal,
  },
  filters: {
    centsToEuro,
    currency,
  },
})
export default class CreateSellOfferForm extends Vue {
  [ x: string ]: any;

  @Getter('appState/isLoading') isLoading?: boolean;
  @Getter('stepper/steps') steps?: string[];
  @Getter('stepper/activeStep') activeStep?: number;

  @Action('appState/startLoader') startLoader;
  @Action('appState/stopLoader') stopLoader;
  @Action('trading/createSellOffer') createSellOffer;
  @Action('stepper/setSteps') setSteps;
  @Action('stepper/nextStep') nextStep;
  @Action('stepper/backStep') backStep;
  @Action('stepper/setValidCurrentStep') setValidCurrentStep;

  @Prop({ required: true })
  asset: Asset;

  active: number = 0;
  sellOffer: SellOffer;
  createdSuccess: boolean = false;
  checkedOptions: Array<string> = [];
  message: any = null;

  sellOfferForm = {
    available: null,
    unit_price: null,
  }
  rules = {
    available: [
      {
        required: true,
        message: this.$t('sellOffer.validationMessages.requiredMaxPieces'),
        trigger: 'blur',
      },
      {
        type: 'number',
        min: 1,
        max: this.asset.pieces,
        message: `${this.$t('sellOffer.validationMessages.typeNumberMaxPieces')} ${this.asset.pieces}`,
        trigger: 'blur',
      },
    ],
    unit_price: [
      {
        required: true,
        message: this.$t('sellOffer.validationMessages.requiredUnitPrice'),
        trigger: 'blur',
      },
      {
        // https://element.eleme.io/#/en-US/component/form#validation
        validator: (rule, value, callback) => {
          if (parseFloat(value) >= 0.01) {
            return callback();
          }

          return callback(new Error(this.$t('sellOffer.validationMessages.typeNumberUnitPrice')));
        },
        trigger: 'blur',
      },
    ],
  }

  get assetIsBaader()  {
    return this.asset?.provider === 'baaderbank';
  }

  get totalAmount() {
    return (this.sellOfferForm.available * this.sellOfferForm.unit_price) || 0;
  }

  get firstStep() {
    return this.active === 0;
  }

  get secondStep() {
    return this.active === 1;
  }

  get hasAgreed() {
    return this.checkedOptions.length > 1;
  }

  get parseUnitPrice(){
    const price = this.sellOfferForm.unit_price;

    return price ? this.$options.filters.currency(price) : null;
  }

  mounted() {
    const steps: Steps = [
      {
        title: this.$t('sellOffer.stepper', { context: 'step1' }),
        valid: null,
      },
      {
        title: this.$t('sellOffer.stepper', { context: 'step2' }),
        valid: null,
      },
    ];
    this.setSteps(steps);
  }

  async next() {
    if (this.firstStep) {
      try {
        await this.$refs[ 'sellOfferForm' ][ 'validate' ]();
      } catch (error) {
        this.setValidCurrentStep(false);

        return;
      }
    }
    this.scrollToTop();
    this.active++;
    this.setValidCurrentStep(true);
    this.nextStep();
  }

  cancel() {
    if(this.redirectUrl){
      return window.location.href = this.redirectUrl;
    }

    return this.$router.push('/');
  }

  back() {
    this.active--;
    this.backStep();
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  closeMessageModal() {
    this.message = null;
  }

  async onCreateSubmit() {
    this.startLoader();
    try {
      const sellOffer: CreateSellOffer = {
        asset_id: this.asset.asset_id,
        available: this.sellOfferForm.available,
        unit_price: this.sellOfferForm.unit_price * 100,
      };
      await this.createSellOffer(sellOffer);

      this.onSubmitSuccess();
    } catch (error) {
      this.onSubmitError(_.get(error, 'response') || error);
    }
  }

  onSubmitSuccess() {
    this.createdSuccess = true;
    this.message = this.successMessage;
    this.stopLoader();
    this.$router.push('/depot/trading');
  }

  onSubmitError(error) {
    const customStatus = 416;
    const { status, data } = error;    
    
    const message = data.error;

    if (customStatus === status) {
      let transKey = 'low';
      if (message && message.toLowerCase().includes('high')) {
        transKey = 'high';
      }

      this.message = this.$t(`buySellOffer.messages.info_${customStatus}.${transKey}`, {
        link: `<a target='_blank' href='${this.$t('buySellOffer.messages.linkPath')}'>
              ${this.$t('buySellOffer.messages.linkTitle')}
              </a>` });

    } else {
      // show a generic error message
      this.message = this.$t('sellOffer.error.generic.title') + '.<br /><br />'+this.$t('sellOffer.error.generic.text', {
        link: `<a target='_blank' href='${this.$t('sellOffer.error.generic.linkPath')}'>
            ${this.$t('error.generic.linkTitle')}
            </a>` });
    }

    this.stopLoader();
  }

  async onInputBlur(){
    try{
      await this.$refs[ 'sellOfferForm' ][ 'validateField' ]('unit_price');
    } catch(e){
      console.warn(e);
    }
  }
}
