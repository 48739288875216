
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Asset } from '@/store/trading/types';
import { centsToEuro, currency } from '@/filters/currency';
import Fieldset from '@/components/_common/Fieldset.vue';

@Component({
  name: 'asset-details',
  components: {
    Fieldset,
  },
  filters: {
    centsToEuro,
    currency,
  },
})
export default class AssetDetails extends Vue {
  @Prop({ required: true })
  asset: Asset;
}
