
import { Component, Vue, Prop } from 'vue-property-decorator';
import MessageModal from '@/components/app/MessageModal.vue';
import CreateSellOfferForm from '@/components/trading/create-selloffer/CreateSellOfferForm.vue';
import { Getter, Action } from 'vuex-class';
import { Asset } from '@/store/trading/types';


@Component({
  name: 'create-sell-offer-view',
  components: {
    CreateSellOfferForm,
    MessageModal,
  },
})
export default class CreateSellOfferView extends Vue {
  @Getter('appState/isLoading') isLoading?: boolean;
  @Getter('trading/assets') projects?: Asset[];  
  @Getter('trading/getMyProjectByAssetId') getMyProjectByAssetId: Function

  @Action('trading/fetchMyAsset') fetchMyAsset: Function;

  private project: Asset = null;
  message: any = null;

  async mounted() {
    const assetId = this.$route.params.assetId;        
    await this.fetchMyAsset(assetId);
    this.project = this.getMyProjectByAssetId(assetId);
    
    if(!this.project) {
        this.message = this.$t('sellOffer.messages.info', {
        context: '404',
        link: `<a target='_blank' href='${this.$t('sellOffer.messages.generic.linkPath')}'>
              ${this.$t('sellOffer.messages.generic.linkTitle')}
              </a>` });
    }
  }
}
